import(/* webpackMode: "eager", webpackExports: ["AcquiaStatusDialog"] */ "/app/components/app/status/AcquiaStatus.client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Button"] */ "/app/components/base/Button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ReleaseNotesFilters"] */ "/app/components/block/ReleaseNotes.client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ScrollToHash"] */ "/app/components/block/ScrollToHash.client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Dismissable","DismissButton"] */ "/app/components/widget/Dismiss.client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Faq"] */ "/app/components/widget/Faq.client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ShowMore"] */ "/app/components/widget/ShowMore.client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["highlightLanguages","CodeHighlight"] */ "/app/components/wysiwyg/CodeHighlight.client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ModalImage"] */ "/app/components/wysiwyg/ModalImage.client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SmartLink"] */ "/app/components/wysiwyg/SmartLink.client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Tabs"] */ "/app/components/wysiwyg/Tabs.client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SkipToContentMarker"] */ "/app/contexts/SkipToContentProvider.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/public/assets/wysiwyg.scss");
