'use client'
import React, { ReactNode, useState } from "react"
import { Button } from "../base/Button"

export const ShowMore = ({children, className}: {
    children: ReactNode
    className?: string
}) => {
    const [show, setShow] = useState(false)

    if (show) {
        return <>{children}</>
    }
    return <div className={className}><Button primary={false} color='blue' onClick={() => setShow(true)}>Show more</Button></div>
}